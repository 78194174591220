@font-face {
  font-family: "BasisGrotesquePro";
  src: url("./fonts/Basis-Grotesque-Pro-Regular.woff2") format("woff2"),
    url("./fonts/Basis-Grotesque-Pro-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BasisGrotesquePro Medium";
  src: url("./fonts/Basis-Grotesque-Pro-Medium.woff2") format("woff2"),
    url("./fonts/Basis-Grotesque-Pro-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "BasisGrotesquePro Black";
  src: url("./fonts/Basis-Grotesque-Pro-Black.woff2") format("woff2"),
    url("./fonts/Basis-Grotesque-Pro-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "BasisGrotesquePro Bold";
  src: url("./fonts/Basis-Grotesque-Pro-Bold.woff2") format("woff2"),
    url("./fonts/Basis-Grotesque-Pro-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

.BasisGrotesquePro {
  font-family: "BasisGrotesquePro";
}

@font-face {
  font-family: "CircularStd";
  src: url("./fonts/CircularStd-Book.woff2") format("woff2"),
    url("./fonts/CircularStd-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "CircularStd Medium";
  src: url("./fonts/CircularStd-Medium.woff2") format("woff2"),
    url("./fonts/CircularStd-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "CircularStd Black";
  src: url("./fonts/CircularStd-Black.woff2") format("woff2"),
    url("./fonts/CircularStd-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "CircularStd Bold";
  src: url("./fonts/CircularStd-Bold.woff2") format("woff2"),
    url("./fonts/CircularStd-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

.CircularStd {
  font-family: "CircularStd";
}
