@import "./fonts.css";

* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

body {
  font-family: "BasisGrotesquePro";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 0;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a {
  text-decoration: none;
  color: #1673fa;
  display: flex;
}

img {
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  display: block;
}

button {
  cursor: pointer;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

textarea {
  resize: none;
}

.width-100 {
  width: 100% !important;
}

.flex {
  display: flex;
}

.mr-8 {
  margin-right: 8px;
}
